<template>
  <div class="cr-card-detail-option" v-if="show">
    <v-icon>
      mdi-comment-text-outline
    </v-icon>

    <div v-if="!isEdit" class="d-flex flex-grow-1 text-pre">
      {{ memo }}
    </div>
    <div v-else class="cr-edit-wrapper py-2">
      <v-textarea
        ref="memo"
        rows="1"
        auto-grow
        hide-details
        class="flex-grow-1"
        :label="$t('contact.메모')"
        v-model="memo"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-textarea::v-deep {
  fieldset {
    border-radius: 16px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
// import { isBlank } from "@/commons/utils/validation";

export default {
  data({ $store: { getters } }) {
    const { memo } = getters["cardDetail/detail"];
    return { memo };
  },
  computed: {
    ...mapGetters("cardDetail", ["isEdit", "detail"]),
    show() {
      return true;
      /*
      if (this.isEdit) return true;
      return !isBlank(this.memo ?? "");
       */
    }
  },
  watch: {
    isEdit(isEdit) {
      if (isEdit) return;

      this.memo = this.detail?.memo || "";
    }
  },
  methods: {
    getData() {
      if (!this.isEdit) return;
      return { memo: this.memo };
    }
  }
};
</script>
